import {url} from "../../../../shared/utils";

$(document).ready(function() {
    $(".sliding-panel__btn").on("click", function(event) {
        var path = url('hamburger/open');
        if (typeof ga != 'undefined') {
            ga('send', 'pageview', path);
        }

        event.preventDefault();//the default action of the event will not be triggered

        if ($(".sliding-panel-ini").hasClass("sliding-panel-ini-open")) {
            $(".sliding-panel-ini").removeClass("sliding-panel-ini-open");
        } else {
            $(".sliding-panel-ini").addClass("sliding-panel-ini-open");
        }
    });

    $(".sliding-panel__close").on("click", function(event) {
        var path = url('hamburger/close');
        if (typeof ga != 'undefined') {
            ga('send', 'pageview', path);
        }

        $(".sliding-panel-ini").removeClass("sliding-panel-ini-open");
    });
});
