export const GoogleTranslateExt = (function() {
    const TRANSLATE_ELEMENT_SELECTOR = '#google_translate_element select';

    const exports = {};

    function trackLanguageChange(langCode) {
        window.Analytics.track('49|10. Front End Usage', {
            type_id: 286,
            eventData: {
                "event_cat_id": 49,
                "event_cat_name": "10. Front End Usage",
                "tl_event_id": 286,
                "tl_event_name": "Translate Button Clicked",
                "tl_event_data": langCode
            },
            localData: {
                datavar: langCode
            }
        });
    }

    exports.init = function() {
        $('body').on('click', function (e) {
            // hide google translate dropdown when anything else in the body is clicked
            if (!$('#google_translate_dropdown').is(e.target) &&
                $('#google_translate_dropdown').has(e.target).length === 0) {
                $('#google_translate_dropdown').collapse('hide');
            }
        });

        $('[data-target="#google_translate_dropdown"]').click(function() {
            const top   = this.getBoundingClientRect().bottom;
            const arrow = $(this).find('i.fa-caret-down')[0];
            const right = window.innerWidth - arrow.getBoundingClientRect().right - 20;
            const isOverLeftBorder = right + $('#google_translate_dropdown').width() > window.innerWidth;

            // if element's left is less than zero, set it to 0
            $('#google_translate_dropdown').css({
                top:    top,
                right:  isOverLeftBorder ? 'auto' : right,
                left:   isOverLeftBorder ? 0 : 'auto',
            });
        });

        $(document).on('change', TRANSLATE_ELEMENT_SELECTOR, function() {
            console.log(this.value);
            trackLanguageChange(this.value + ' (manual)');
            $('#google_translate_dropdown').collapse('hide');
        });

        if ($('.header-v6.logged_out').length > 0) {
            if (document.cookie.indexOf('googtransopt=os=1') !== -1) {
                return;
            }

            var renderedTranslateEl = false;

            const intervalId = setInterval(function() {
                const translateEl = document.querySelector(TRANSLATE_ELEMENT_SELECTOR);
                renderedTranslateEl = translateEl !== null;

                if (renderedTranslateEl) {
                    if (translateEl.children.length > 0) {
                        if ('' === translateEl.children[0].value && 'Select Language' !== translateEl.children[0].innerText) {
                            var langCode = translateEl.children[1].value;
                            translateEl.value = langCode;
                            translateEl.dispatchEvent(new Event('change'));

                            // preventing logging duplicated events for each page
                            if (document.cookie.indexOf('googtrans') === -1) {
                                trackLanguageChange(langCode);
                            }
                        }

                        clearInterval(intervalId);
                    }
                }
            }, 1000);
        }
    };

    return exports;
})();
