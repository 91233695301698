import 'bootstrap';

export const ColorMode = (function() {
    const COLOR_MODE = {
        dark: 'dark',
        light: 'light'
    };

    const BODY_CLASS = 'dark-mode';

    const exports = {};

    exports.init = function() {
        $('#mode_switcher_button').click(function() {
            if (window.lightMode === COLOR_MODE.dark) {

                try {
                    localStorage.setItem('color-mode', COLOR_MODE.light);
                } catch (e) {}

                window.lightMode = COLOR_MODE.light;
                $('body').removeClass(BODY_CLASS);

                window.Analytics.track('49|10. Front End Usage',{
                    type_id: 270,
                    eventData: {
                        "event_cat_id": 49,
                        "event_cat_name": "10. Front End Usage",
                        "tl_event_id": 270,
                        "tl_event_name":"Switched to Day Mode",
                        "tl_event_data": 'day'
                    },
                    localData: {
                        datavar: 'day-mode'
                    }
                });

            } else {

                try {
                    localStorage.setItem('color-mode', COLOR_MODE.dark);
                } catch (e) {}

                window.lightMode = COLOR_MODE.dark;
                $('body').addClass(BODY_CLASS);

                window.Analytics.track('49|10. Front End Usage',{
                    type_id: 271,
                    eventData: {
                        "event_cat_id": 49,
                        "event_cat_name": "10. Front End Usage",
                        "tl_event_id": 271,
                        "tl_event_name":"Switched to Night Mode",
                        "tl_event_data": 'night'
                    },
                    localData: {
                        datavar: 'night-mode'
                    }
                });
            }
        });

        $('#mode_switcher_button img').tooltip();
    };

    return exports;
})();
